import { motion } from "framer-motion";
import "./App.css";
import logo from './logo.png'

export default function App() {
  return (
    <div className="container">
      <div className="overlay"></div>
      <motion.div 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 1 }} 
        className="content"
      >
        <img src={logo}/>
        <h1 className="title">Ultra Aluminium</h1>
        <p className="description">Our website is under construction. Stay tuned for updates!</p>
        <motion.div
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ repeat: Infinity, duration: 1.5 }}
          className="coming-soon"
        >
          🚧 Coming Soon 🚧
        </motion.div>
      </motion.div>
    </div>
  );
}
